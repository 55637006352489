import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat5, addProgramaCat5 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Pregunta } from '../../helpers/Pregunta';

export const FormularioCat5 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat5_1: "",
    cat5_2: "",
    cat5_3: "",
    cat5_4: "",
    cat5_5: "",
    cat5_6: "",
    cat5_7: "",
    cat5_8: "",
    cat5_9: "",
    cat5_10: "",
    cat5_11: "",
    cat5_12: "",
    cat5_13: "",
    
});

const { cat5_1, cat5_2, cat5_3, cat5_4, cat5_5, cat5_6,cat5_7,cat5_8,cat5_9,cat5_10,cat5_11,cat5_12,cat5_13} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat5(auth.tokenJwt,cat5_1, cat5_2, cat5_3, cat5_4, cat5_5, cat5_6,cat5_7,cat5_8,cat5_9,cat5_10,cat5_11,cat5_12,cat5_13,configFormulario.infoFormulario.IdEvaluacion));
      
      reset()

    }else{
      console.log("out");
      //setMsgError("")
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat5_1) || !cat5_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.1 + t("formulario.msgError2");}
    if(isNaN(cat5_2) || !cat5_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.2 + t("formulario.msgError2");}
    if(isNaN(cat5_3) || !cat5_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.3 + t("formulario.msgError2");}
    if(isNaN(cat5_4) || !cat5_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.4 + t("formulario.msgError2");}
    if(isNaN(cat5_5) || !cat5_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.5 + t("formulario.msgError2");}
    if(isNaN(cat5_6) || !cat5_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.6 + t("formulario.msgError2");}
    if(isNaN(cat5_7) || !cat5_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.7 + t("formulario.msgError2");}
    if(isNaN(cat5_8) || !cat5_8 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.8 + t("formulario.msgError2");}
    if(isNaN(cat5_9) || !cat5_9 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.9 + t("formulario.msgError2");}
    if(isNaN(cat5_10) || !cat5_10 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.10 + t("formulario.msgError2");}
    if(isNaN(cat5_11) || !cat5_11 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.11 + t("formulario.msgError2");}
    if(isNaN(cat5_12) || !cat5_12 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.12 + t("formulario.msgError2");}
    if(isNaN(cat5_13) || !cat5_13  ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.13 + t("formulario.msgError2");}

    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat5(auth.tokenJwt,cat5_1, cat5_2,cat5_3,cat5_4,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat5_1) || !cat5_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.1 + t("formulario.msgError2");}
    if(isNaN(cat5_2) || !cat5_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.2 + t("formulario.msgError2");} 
    if(isNaN(cat5_3) || !cat5_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.3 + t("formulario.msgError2");}
    if(isNaN(cat5_4) || !cat5_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 5.4 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  return (
    <>
      <div className="ac-panel">
        <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
          {msgError != null && (
            <div className="alert alert-danger">{msgError}</div>
          )}
  
          {configFormulario.infoFormularioCategoria.ResultatCat5 == null ? (
            <>
              {/* Formulario de Entrada */}
              <Pregunta
                label={t("Categoria5.preg1")}
                name="cat5_1"
                value={formValues.cat5_1}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "1", label: t("CategoriaDesplegable.5.1_1") },
                  { value: "0", label: t("CategoriaDesplegable.5.1_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria5.preg2")}
                name="cat5_2"
                value={formValues.cat5_2}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria5.preg3")}
                name="cat5_3"
                value={formValues.cat5_3}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria5.preg4")}
                name="cat5_4"
                value={formValues.cat5_4}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria5.preg5")}
                name="cat5_5"
                value={formValues.cat5_5}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria5.preg6")}
                name="cat5_6"
                value={formValues.cat5_6}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria5.preg7")}
                name="cat5_7"
                value={formValues.cat5_7}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria5.preg8")}
                name="cat5_8"
                value={formValues.cat5_8}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria5.preg9")}
                name="cat5_9"
                value={formValues.cat5_9}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.5AnyAnterior_2") },
                  { value: "1", label: t("CategoriaDesplegable.5AnyAnterior_1") },
                  { value: "0", label: t("CategoriaDesplegable.5AnyAnterior_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria5.preg10")}
                name="cat5_10"
                value={formValues.cat5_10}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.5AnyAnterior_2") },
                  { value: "1", label: t("CategoriaDesplegable.5AnyAnterior_1") },
                  { value: "0", label: t("CategoriaDesplegable.5AnyAnterior_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria5.preg11")}
                name="cat5_11"
                value={formValues.cat5_11}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.5AnyAnterior_2") },
                  { value: "1", label: t("CategoriaDesplegable.5AnyAnterior_1") },
                  { value: "0", label: t("CategoriaDesplegable.5AnyAnterior_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria5.preg12")}
                name="cat5_12"
                value={formValues.cat5_12}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.5AnyAnterior_2") },
                  { value: "1", label: t("CategoriaDesplegable.5AnyAnterior_1") },
                  { value: "0", label: t("CategoriaDesplegable.5AnyAnterior_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria5.preg13")}
                name="cat5_13"
                value={formValues.cat5_13}
                onChange={handleInputChange}
                type="text"
              />
              <div>
                <button
                  onClick={handleCalcularCat}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* Formulario de Evaluación */}
              {[1, 2, 3, 4].map((num) => (
                <Pregunta
                  key={`cat5_${num}`}
                  label={t(`CategoriaPrograma.5_${num}`)}
                  name={`cat5_${num}`}
                  value={formValues[`cat5_${num}`]}
                  onChange={handleInputChange}
                  type="select"
                  options={[
                    { value: "", label: t("CategoriaDesplegable.seleccionar") },
                    { value: "2", label: t("CategoriaDesplegable.si") },
                    { value: "1", label: t("CategoriaDesplegable.mitat") },
                    { value: "0", label: t("CategoriaDesplegable.no") },
                  ]}
                />
              ))}
              <div>
                <button
                  onClick={handleCalcularPrograma}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
  
}
