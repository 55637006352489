import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat8, addProgramaCat8 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Pregunta } from '../../helpers/Pregunta';

export const FormularioCat8 = () => {
  const dispatch = useDispatch(); 
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  const [t, i18n] = useTranslation("generic");
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat8_1: "",
    cat8_2: "",
    cat8_3: "",
    cat8_4: "",
    cat8_5: "",
    cat8_6: "",
    cat8_7: "",
    cat8_8: "",
    cat8_9: "",
    
});

const { cat8_1, cat8_2, cat8_3, cat8_4, cat8_5, cat8_6,cat8_7,cat8_8,cat8_9} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat8(auth.tokenJwt,cat8_1, cat8_2, cat8_3, cat8_4, cat8_5, cat8_6,cat8_7,cat8_8,cat8_9,configFormulario.infoFormulario.IdEvaluacion));
      reset();
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat8(auth.tokenJwt,cat8_1, cat8_2,configFormulario.infoFormulario.IdEvaluacion));
    }else{
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat8_1) || !cat8_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.1 + t("formulario.msgError2");}
    if(isNaN(cat8_2) || !cat8_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.2 + t("formulario.msgError2");} 
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat8_1) || !cat8_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.1 + t("formulario.msgError2");}
    if(isNaN(cat8_2) || !cat8_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.2 + t("formulario.msgError2");}
    if(isNaN(cat8_3) || !cat8_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.3 + t("formulario.msgError2");} 
    if(isNaN(cat8_4) || !cat8_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.4 + t("formulario.msgError2");}
    if(isNaN(cat8_5) || !cat8_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.5 + t("formulario.msgError2");}
    if(isNaN(cat8_6) || !cat8_6 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.6 + t("formulario.msgError2");}
    if(isNaN(cat8_7) || !cat8_7 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.7 + t("formulario.msgError2");}
    if(isNaN(cat8_8) || !cat8_8 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.8 + t("formulario.msgError2");}
    if(isNaN(cat8_9) || !cat8_9 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 8.9 + t("formulario.msgError2");}
    if(cat8_8 == ""){esCorrecte =false;}

    if(!esCorrecte ){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  return (
    <>
      <div className="ac-panel">
        <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
          {msgError != null && (
            <div className="alert alert-danger">{msgError}</div>
          )}
  
          {configFormulario.infoFormularioCategoria.ResultatCat8 == null ? (
            <>
              {/* Usando Pregunta para entradas y select */}
              <Pregunta
                label={t("Categoria8.preg1")}
                name="cat8_1"
                value={formValues.cat8_1}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria8.preg2")}
                name="cat8_2"
                value={formValues.cat8_2}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria8.preg3")}
                name="cat8_3"
                value={formValues.cat8_3}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria8.preg4")}
                name="cat8_4"
                value={formValues.cat8_4}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria8.preg5")}
                name="cat8_5"
                value={formValues.cat8_5}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria8.preg6")}
                name="cat8_6"
                value={formValues.cat8_6}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "3", label: t("CategoriaDesplegable.8.6_3") },
                  { value: "2", label: t("CategoriaDesplegable.8.6_2") },
                  { value: "1", label: t("CategoriaDesplegable.8.6_1") },
                  { value: "0", label: t("CategoriaDesplegable.8.6_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria8.preg7")}
                name="cat8_7"
                value={formValues.cat8_7}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "3", label: t("CategoriaDesplegable.8.7_3") },
                  { value: "2", label: t("CategoriaDesplegable.8.7_2") },
                  { value: "1", label: t("CategoriaDesplegable.8.7_1") },
                  { value: "0", label: t("CategoriaDesplegable.8.7_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria8.preg8")}
                name="cat8_8"
                value={formValues.cat8_8}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.8.8_2") },
                  { value: "1", label: t("CategoriaDesplegable.8.8_1") },
                  { value: "0", label: t("CategoriaDesplegable.8.8_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria8.preg9")}
                name="cat8_9"
                value={formValues.cat8_9}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "3", label: t("CategoriaDesplegable.8.9_3") },
                  { value: "2", label: t("CategoriaDesplegable.8.9_2") },
                  { value: "1", label: t("CategoriaDesplegable.8.9_1") },
                  { value: "0", label: t("CategoriaDesplegable.8.9_0") },
                ]}
              />
              <div>
                <button
                  onClick={handleCalcularCat}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* Usando Pregunta para la evaluación */}
              <Pregunta
                label={t("CategoriaPrograma.8_1")}
                name="cat8_1"
                value={formValues.cat8_1}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.si") },
                  { value: "1", label: t("CategoriaDesplegable.mitat") },
                  { value: "0", label: t("CategoriaDesplegable.no") },
                ]}
              />
              <Pregunta
                label={t("CategoriaPrograma.8_2")}
                name="cat8_2"
                value={formValues.cat8_2}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.si") },
                  { value: "1", label: t("CategoriaDesplegable.mitat") },
                  { value: "0", label: t("CategoriaDesplegable.no") },
                ]}
              />
              <div>
                <button
                  onClick={handleCalcularPrograma}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
  
}
