import React, { useEffect, useState } from 'react'
import { UsuariLoginat } from '../ui/UsuariLoginat'

import {  NavLink, useNavigate } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { recuperarConfigUsuari } from '../../actions/configUsuariAction';
import { useTranslation } from 'react-i18next';
import { CercaTable } from './Tabla/CercaTable';
import { recuperarHistorial2 } from '../../actions/configAppAction';
import { infoTextCategoria, iniciarFormulario } from '../../actions/configFormularioAction';
import { ModificarScreen } from '../register/ModificarScreen';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import { CercaTableADMIN } from './Tabla/CercaTableADMIN';
import { uiIniciarFormularioAction } from '../../actions/ui';

export const MenuPrincipalADMIN = () => {
    const dispatch = useDispatch();
    const navegacio = useNavigate();
    
  const [t, i18n] = useTranslation("generic");
    
    const { auth,configUsuari,ui } = useSelector(state => state);
    const [year, setYear] = useState(moment().format('YYYY'))
  const { msgError,formulari} = useSelector((state) => state.ui);
  const { contingut} = useSelector((state) => state.configUsuari);

  useEffect(() => {
    dispatch(recuperarConfigUsuari(auth.tokenJwt)); 
  }, [auth]);

      useEffect(() => {
        if(formulari){
          navegacio("/evaluacion");
        }else{
          navegacio("/");
        }
      }, [ui]);

      const handleFormulari = (e) => {
        e.preventDefault();
        dispatch(infoTextCategoria(auth.tokenJwt));
        dispatch(uiIniciarFormularioAction())
        
      };
  return (
    <>

    <div className="grid grid-cols-1 md:grid-cols-1 items-end gap-6">
      <div>

        <p className="uppercase font-medium text-sm tracking-[0.84px] after:bg-green-semilight after:absolute after:w-full after:h-2 relative after:left-0 inline-block after:bottom-0 after:-z-10">Municipio</p>
        {configUsuari.contingut!=null ?<h2 className="font-semibold mt-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">{t("Menu.title")} {year}</h2>:null}
      </div>
      <div className="flex md:flex-row flex-col md:items-center gap-2 md:gap-16 justify-end max-w-sm md:ml-auto">
        <div className="flex items-center text-left gap-4">
        {msgError != null  ? ( //això es una condició, si es diferent de null
                  <div className="alert alert-danger">{msgError}</div>
                ) : null}<button
                onClick={handleFormulari}
                className="btn-green"
              >
                Moficar Textos
              </button>
                  
        </div>
      </div>
    </div>
        {configUsuari.contingut!=null?
          <ModificarScreen/>
        :null
        }
        <div className="mt-4">
        <CercaTableADMIN/>
        </div>
        
        
        
        
    </>
  )
}
