import { types } from "../types/types";

export const configFormularioReducer = (state = {},action) => {

    switch (action.type){
        
        // case types.configFormularioRecuperarConstantesFormulario:
        //     return {...state,
        //         constantesFormulario : action.contingut
        //         }
        // case types.configFormularioRecuperarIndicadoresFormulario:
        //     return {...state,
        //         indicadoresFormulario : action.contingut
        //         }
       case types.configFormularioInfo:
            return {...state,
                infoFormulario : action.contingut
                }
       case types.configFormularioCategoria:
            return {...state,
                infoFormularioCategoria : action.contingut
                }
        case types.configTextFormularioCategoria:
            return {...state,
                infoFormularioTextCategoria : action.contingut
                }
        case types.configFormularioResultats:
            return {...state,
                resultatsCategoria : action.contingut
                }
                case types.configActualizarTextoCategoria:
                    return {
                        ...state,
                        infoFormularioTextCategoria: {
                            ...state.infoFormularioTextCategoria,
                            [action.categoria]: {
                                // Mantenemos los textos existentes y solo actualizamos los modificados
                                ...state.infoFormularioTextCategoria[action.categoria],
                                ...action.nuevosTextos, // Aquí aplicamos las modificaciones
                            },
                        },
                    };
        
        default:
            return state;
    }

}