import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat4, addProgramaCat4 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { useForm } from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { Pregunta } from '../../helpers/Pregunta';

export const FormularioCat4 = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation('generic');
  const { auth, configFormulario, ui } = useSelector((state) => state);
  const { msgError } = ui;

  const [formValues, handleInputChange, reset] = useForm({});

  // Generar preguntas dinámicamente
  const questions = configFormulario.infoFormularioTextCategoria
    ? Object.entries(configFormulario.infoFormularioTextCategoria.Categoria4)
        .filter(([key]) => key.startsWith('Cat4_Preg')) // Filtrar preguntas principales
        .map(([key, value]) => {
          // Verificar si la pregunta tiene un desplegable asociado
          const desplegableKey = `Cat4_Desplegable${key.match(/Preg(\d+)/)?.[1]}`;
          const desplegable = Object.entries(configFormulario.infoFormularioTextCategoria.Categoria4).filter(([dKey]) =>
            dKey.startsWith(desplegableKey)
          );

          const options = desplegable.length > 0
            ? desplegable.map(([dKey, dValue]) => ({
                value: dKey.split('_').pop(), // Tomar el último número como valor
                label: dValue,
              })).reverse()
            : [];

          // Añadir la opción de "Seleccionar"
          options.unshift({
            value: "", // Valor para "Seleccionar"
            label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Seleccionar, // Texto para la opción
          });

          return {
            id: key,
            label: value,
            type: desplegable.length > 0 ? 'select' : 'text', // Campo de texto por defecto
            options: options,
          };
        })
    : [];

  const programQuestions = configFormulario.infoFormularioTextCategoria
    ? Object.entries(configFormulario.infoFormularioTextCategoria.Categoria4)
        .filter(([key]) => key.startsWith('Cat4_Prog')) // Preguntas de programa
        .map(([key, value]) => ({
          id: key,
          label: value,
          type: 'select',
          options: [
            { value: '', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Seleccionar },
            { value: '2', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Si },
            { value: '1', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Mitat },
            { value: '0', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_No },
          ],
        }))
    : [];

  const handleCalcularCat = () => {
    if (validarFormulas()) {
      const payload = questions.map((q) => formValues[q.id]);
      dispatch(addFormularioCat4(auth.tokenJwt, ...payload, configFormulario.infoFormulario.IdEvaluacion));
      reset();
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError = '';

    questions.forEach((question, index) => {
      const value = formValues[question.id];
      if (isNaN(value) || !value) {
        esCorrecte = false;
        msgError += `${t('formulario.msgError1')}${index + 1} ${t('formulario.msgError2')}\n`;
      }
    });

    if (!esCorrecte) {
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  };

  const handleCalcularPrograma = () => {
    if (validarProgramas()) {
      const payload = programQuestions.map((q) => formValues[q.id]);
      dispatch(addProgramaCat4(auth.tokenJwt, ...payload, configFormulario.infoFormulario.IdEvaluacion));
    }
  };

  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError = '';

    programQuestions.forEach((question, index) => {
      const value = formValues[question.id];
      if (isNaN(value) || !value) {
        esCorrecte = false;
        msgError += `${t('formulario.msgError1')}${index + 1} ${t('formulario.msgError2')}\n`;
      }
    });

    if (!esCorrecte) {
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  };

  return (
    <div className="ac-panel">
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError && <div className="alert alert-danger">{msgError}</div>}

        {configFormulario.infoFormularioCategoria.ResultatCat4 == null ? (
          <>
            {questions.map((question) => (
              <Pregunta
                key={question.id}
                label={question.label}
                name={question.id}
                value={formValues[question.id] || ''}
                onChange={handleInputChange}
                type={question.type}
                options={question.options}
              />
            ))}
            <div>
              <button onClick={handleCalcularCat} className="btn-turquesa">
                {t('formulario.enviar')}
              </button>
            </div>
          </>
        ) : (
          <>
            {programQuestions.map((question) => (
              <Pregunta
                key={question.id}
                label={question.label}
                name={question.id}
                value={formValues[question.id] || ''}
                onChange={handleInputChange}
                type={question.type}
                options={question.options}
              />
            ))}
            <div>
              <button onClick={handleCalcularPrograma} className="btn-turquesa">
                {t('formulario.enviar')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
