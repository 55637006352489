import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat9, addProgramaCat9, infoCategoria } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Pregunta } from '../../helpers/Pregunta';

export const FormularioCat9 = () => {
  const dispatch = useDispatch(); 
  
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  const [t, i18n] = useTranslation("generic");
  const { auth,configFormulario} = useSelector(state => state);
  const {infoFormulario,infoFormularioCategoria} = configFormulario;
  const [formValues, handleInputChange,reset] = useForm({
    cat9_1: "",
    cat9_2: "",
    cat9_3: "",
    cat9_4: "", 
});

const { cat9_1, cat9_2, cat9_3, cat9_4} = formValues;

  const handleCalcularCat = (e) => {
    if(validarFormulas()){
      
      dispatch(addFormularioCat9(auth.tokenJwt,cat9_1, cat9_2, cat9_3, cat9_4,configFormulario.infoFormulario.IdEvaluacion));
      reset();
    }else{
      console.log("out");
      //setMsgError("")
    }
  };
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat9(auth.tokenJwt,cat9_1, cat9_2,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat9_1) || !cat9_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.1 + t("formulario.msgError2");}
    if(isNaN(cat9_2) || !cat9_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.2 + t("formulario.msgError2");} 
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat9_1) || !cat9_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.1 + t("formulario.msgError2");}
    if(isNaN(cat9_2) || !cat9_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.2 + t("formulario.msgError2");}
    if(isNaN(cat9_3) || !cat9_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.3 + t("formulario.msgError2");}
    if(isNaN(cat9_4) || !cat9_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 9.4 + t("formulario.msgError2");}
    
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  

  return (
    <>
      <div className="ac-panel">
        <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
          {msgError != null && (
            <div className="alert alert-danger">{msgError}</div>
          )}
  
          {configFormulario.infoFormularioCategoria.ResultatCat9 == null ? (
            <>
              {/* Usando Pregunta para el formulario de entrada */}
              <Pregunta
                label={t("Categoria9.preg1")}
                name="cat9_1"
                value={formValues.cat9_1}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria9.preg2")}
                name="cat9_2"
                value={formValues.cat9_2}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria9.preg3")}
                name="cat9_3"
                value={formValues.cat9_3}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "3", label: t("CategoriaDesplegable.9.X_3") },
                  { value: "2", label: t("CategoriaDesplegable.9.X_2") },
                  { value: "1", label: t("CategoriaDesplegable.9.X_1") },
                  { value: "0", label: t("CategoriaDesplegable.9.X_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria9.preg4")}
                name="cat9_4"
                value={formValues.cat9_4}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "3", label: t("CategoriaDesplegable.9.X_3") },
                  { value: "2", label: t("CategoriaDesplegable.9.X_2") },
                  { value: "1", label: t("CategoriaDesplegable.9.X_1") },
                  { value: "0", label: t("CategoriaDesplegable.9.X_0") },
                ]}
              />
              <div>
                <button
                  onClick={handleCalcularCat}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* Usando Pregunta para el formulario de evaluación */}
              <Pregunta
                label={t("CategoriaPrograma.9_1")}
                name="cat9_1"
                value={formValues.cat9_1}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.si") },
                  { value: "1", label: t("CategoriaDesplegable.mitat") },
                  { value: "0", label: t("CategoriaDesplegable.no") },
                ]}
              />
              <Pregunta
                label={t("CategoriaPrograma.9_2")}
                name="cat9_2"
                value={formValues.cat9_2}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.si") },
                  { value: "1", label: t("CategoriaDesplegable.mitat") },
                  { value: "0", label: t("CategoriaDesplegable.no") },
                ]}
              />
              <div>
                <button
                  onClick={handleCalcularPrograma}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
  
}
