import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat2, addProgramaCat2, infoCategoria, infoTextCategoria } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Pregunta } from '../../helpers/Pregunta';


export const FormularioCat2 = () => {

  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const { infoFormularioTextCategoria } = useSelector((state) => state.configFormulario);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  // useEffect(() => {
  //   if (!infoFormularioTextCategoria) {
  //     //dispatch(infoTextCategoria(auth.tokenJwt, 2)); // Cambia el '2' por el ID de la categoría que corresponda
  //   console.log("inCat2")
  //   }
  // }, [infoFormularioTextCategoria, dispatch, auth.tokenJwt]);

  const [formValues, handleInputChange, reset] = useForm({});

const questions = infoFormularioTextCategoria
    ?  Object.entries(infoFormularioTextCategoria.Categoria2)
    .filter(([key]) => key.startsWith('Cat2_Preg')) // Filtrar solo las preguntas
    .map(([key, value]) => ({
      id: key,
      label: value,
      type: 'text',
    })): [];
  // Construir las preguntas de programa desde infoFormularioTextCategoria
  const programQuestions = infoFormularioTextCategoria
  ?  Object.entries(infoFormularioTextCategoria.Categoria2)
    .filter(([key]) => key.startsWith('Cat2_Prog')) // Filtrar solo las preguntas de programa
    .map(([key, value]) => ({
      id: key,
      label: value,
      type: 'select',
      options: [
        { value: '', label: infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Seleccionar },
        { value: '2', label: infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Si},
        { value: '1', label: infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Mitat },
        { value: '0', label: infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_No },
      ],
    })): [];


    const handleCalcularCat = () => {
      if (validarFormulas()) {
        const payload = questions.map((q) => formValues[q.id]);
        dispatch(addFormularioCat2(auth.tokenJwt, ...payload, configFormulario.infoFormulario.IdEvaluacion));
        reset();
      }
    };

    const validarFormulas = () => {
      let esCorrecte = true;
      let msgError = "";
  
      questions.forEach((question, index) => {
        const value = formValues[question.id];
        if (isNaN(value) || !value) {
          esCorrecte = false;
          msgError += `${t("formulario.msgError1")}${index + 1} ${t("formulario.msgError2")}\n`;
        }
      });
  
      if (!esCorrecte) {
        dispatch(crearErrorAction(msgError));
      }
      return esCorrecte;
    };
    const handleCalcularPrograma = () => {
      if (validarProgramas()) {
        const payload = programQuestions.map((q) => formValues[q.id]);
        dispatch(addProgramaCat2(auth.tokenJwt, ...payload, configFormulario.infoFormulario.IdEvaluacion));
      }
    };
  
    const validarProgramas = () => {
      let esCorrecte = true;
      let msgError = "";
  
      programQuestions.forEach((question, index) => {
        const value = formValues[question.id];
        if (isNaN(value) || !value) {
          esCorrecte = false;
          msgError += `${t("formulario.msgError1")}${index + 1} ${t("formulario.msgError2")}\n`;
        }
      });
  
      if (!esCorrecte) {
        dispatch(crearErrorAction(msgError));
      }
      return esCorrecte;
    };
  return (
    <div className="ac-panel">
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError != null ? <div className="alert alert-danger">{msgError}</div> : null}
        {configFormulario.infoFormularioCategoria.ResultatCat2 == null ? (
          <>
            {questions.map((question) => (
              <Pregunta
                key={question.id}
                label={question.label}
                name={question.id}
                value={formValues[question.id]}
                onChange={handleInputChange}
                type={question.type}
                options={question.options}
                
              />
            ))}
            <div>
              <button onClick={handleCalcularCat} id="cat1" className="btn-turquesa">{t("formulario.enviar")}</button>
            </div>
          </>
        ) : (
          <>
            {programQuestions.map((question) => (
              <Pregunta
                key={question.id}
                label={question.label}
                name={question.id}
                value={formValues[question.id]}
                onChange={handleInputChange}
                type={question.type}
                options={question.options}
              />
            ))}
            <div>
              <button onClick={handleCalcularPrograma} id="cat1" className="btn-turquesa">{t("formulario.enviar")}</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
