import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addFormularioCat7, addProgramaCat7 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { calculoPorcentaje } from '../../helpers/FormulasCat';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Pregunta } from '../../helpers/Pregunta';

export const FormularioCat7 = () => {
  const dispatch = useDispatch(); 
  const [t, i18n] = useTranslation("generic");
  const state = useSelector((state) => state);
  const { msgError } = useSelector((state) => state.ui);
  
  const { auth,configFormulario} = useSelector(state => state);
  const [formValues, handleInputChange,reset] = useForm({
    cat7_1: "",
    cat7_2: "",
    cat7_3: "",
    cat7_4: "",
    cat7_5: "",  
});

const { cat7_1, cat7_2, cat7_3, cat7_4, cat7_5} = formValues;

  const handleCalcularCat = (e) => {
    
    if(validarFormulas()){
      
      dispatch(addFormularioCat7(auth.tokenJwt,cat7_1, cat7_2, cat7_3, cat7_4, cat7_5,configFormulario.infoFormulario.IdEvaluacion));
      reset();
      

    }else{
      console.log("out");
      //setMsgError("")
    }
  };
  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat7_1) || !cat7_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.1 + t("formulario.msgError2");}
    if(isNaN(cat7_2) || !cat7_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.2 + t("formulario.msgError2");}
    if(isNaN(cat7_3) || !cat7_3 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.3 + t("formulario.msgError2");}
    if(isNaN(cat7_4) || !cat7_4 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.4 + t("formulario.msgError2");}
    if(isNaN(cat7_5) || !cat7_5 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.5 + t("formulario.msgError2");}
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }
  const handleCalcularPrograma = (e) => {
    if(validarProgramas()){ 
      dispatch(addProgramaCat7(auth.tokenJwt,cat7_1, cat7_2,configFormulario.infoFormulario.IdEvaluacion));
    }else{
      console.log("out");
    }
  };
  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError ="";
    if(isNaN(cat7_1) || !cat7_1 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.1 + t("formulario.msgError2");}
    if(isNaN(cat7_2) || !cat7_2 ){esCorrecte =false; msgError = msgError + t("formulario.msgError1")+ 7.2 + t("formulario.msgError2");} 
    if(!esCorrecte){
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  }

  return (
    <>
      <div className="ac-panel">
        <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
          {msgError != null && (
            <div className="alert alert-danger">{msgError}</div>
          )}
  
          {configFormulario.infoFormularioCategoria.ResultatCat7 == null ? (
            <>
              {/* Usando Pregunta para entradas y select */}
              <Pregunta
                label={t("Categoria7.preg1")}
                name="cat7_1"
                value={formValues.cat7_1}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "3", label: t("CategoriaDesplegable.7.1_3") },
                  { value: "2", label: t("CategoriaDesplegable.7.1_2") },
                  { value: "1", label: t("CategoriaDesplegable.7.1_1") },
                  { value: "0", label: t("CategoriaDesplegable.7.1_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria7.preg2")}
                name="cat7_2"
                value={formValues.cat7_2}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "3", label: t("CategoriaDesplegable.7.2_3") },
                  { value: "2", label: t("CategoriaDesplegable.7.2_2") },
                  { value: "1", label: t("CategoriaDesplegable.7.2_1") },
                  { value: "0", label: t("CategoriaDesplegable.7.2_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria7.preg3")}
                name="cat7_3"
                value={formValues.cat7_3}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.7.3_2") },
                  { value: "1", label: t("CategoriaDesplegable.7.3_1") },
                  { value: "0", label: t("CategoriaDesplegable.7.3_0") },
                ]}
              />
              <Pregunta
                label={t("Categoria7.preg4")}
                name="cat7_4"
                value={formValues.cat7_4}
                onChange={handleInputChange}
                type="text"
              />
              <Pregunta
                label={t("Categoria7.preg5")}
                name="cat7_5"
                value={formValues.cat7_5}
                onChange={handleInputChange}
                type="text"
              />
              <div>
                <button
                  onClick={handleCalcularCat}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* Usando Pregunta para evaluación */}
              <Pregunta
                label={t("CategoriaPrograma.7_1")}
                name="cat7_1"
                value={formValues.cat7_1}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.si") },
                  { value: "1", label: t("CategoriaDesplegable.mitat") },
                  { value: "0", label: t("CategoriaDesplegable.no") },
                ]}
              />
              <Pregunta
                label={t("CategoriaPrograma.7_2")}
                name="cat7_2"
                value={formValues.cat7_2}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: "", label: t("CategoriaDesplegable.seleccionar") },
                  { value: "2", label: t("CategoriaDesplegable.si") },
                  { value: "1", label: t("CategoriaDesplegable.mitat") },
                  { value: "0", label: t("CategoriaDesplegable.no") },
                ]}
              />
              <div>
                <button
                  onClick={handleCalcularPrograma}
                  id="cat1"
                  className="btn-turquesa"
                >
                  {t("formulario.enviar")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
  
}
