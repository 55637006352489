import React from 'react';

export const Pregunta = ({ index,state,label, name, value, onChange, type = "text", options = [], isAdmin, onQuestionTextChange,previousQuestion = null }) => {
  const renderTitle = () => {
    if (index === 0 && state === 1) {
      return <h3>Modificar Título:</h3>;
    }
    if (index === 1 && state === 1) {
      return <h3>Modificar Preguntas</h3>;
    }
    if (index === 0 && state === 2) {
      return <h3>Modificar Programas</h3>;
    }
    // Lógica específica para desplegables
    if (type === "dropdown" && (!previousQuestion || previousQuestion.type !== "dropdown")) {
      if (name.includes("X_")) {
        return <h3>Modificar Desplegables Comunes</h3>;
      } else {
        const match = name.match(/Desplegable(\d+)/); // Extraer el número del ID
        const numero = match ? match[1] : "Desplegables"; // Si no hay número, usar un título genérico
        return <h3>Modificar Desplegables Pregunta {numero}</h3>;
        
      }
    }
    return null; // No renderiza un título si no aplica
  };
  
  return (
    <div className={`sm:col-span-2 ${type === "dropdown" ? "ml-8" : ""}`}> {/* Ajuste dinámico para desplegables */}
       {renderTitle()}
      {isAdmin ? (
        <div
        className={type === "dropdown" ? "ml-8" : ""} // Clase que agrega margen a la izquierda
        style={type === "dropdown" ? { marginLeft: "2rem" } : {}} // Estilo en línea si es desplegable
      >
        <textarea 
        name={name}
        value={label}  // El valor de la pregunta (texto) que solo el admin puede modificar
        onChange={onQuestionTextChange}  // Permite modificar el texto de la pregunta para el admin
        className="form-control p-3 border rounded-lg shadow-md w-full mt-2 focus:ring-2 focus:ring-green-500 focus:outline-none resize-y "
        placeholder={label}
      />
      </div>
      ) :(
      type === "select" ? (
        <>
        <label htmlFor={name}>{label}</label>
        <select className='form-inputCat' id={name} name={name} value={value} onChange={onChange}>
          {options.map((option) => (
            <option  value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        </>
      ) : (
        <>
        <label htmlFor={name}>{label}</label>
        <input className='form-inputCat'
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          /></>
        )
      )}
    </div>
  );
};