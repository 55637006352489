


//Aquest reducer servirà per despachar accions referents als missatges d'error dels formularis

import { types } from "../types/types"

const initialState = {

    msgError: null, 
    msgOk: null,
    modalUser: false,
    formulari:false,
    resultatsID:null,
    resultatCat:null,
    textosState:null

}


export const uiReducer = (state = initialState, action) =>{

    switch (action.type){
        case types.uiCrearError:
            return{
                ...state,
                msgError: action.payload                
            }
        case types.uiCrearOk:
            return{
                ...state,
                msgOk: action.payload                
            }
        case types.uiEsborrarOk:
            return{
                ...state,
                msgOk: null               
            }

        case types.uiEsborrarError:
            return{
                ...state,
                msgError: null               
            }
        case types.uiModalUser:
            return{
                ...state,
                modalUser: action.payload                
            }
        case types.uiIniciarFormulario:
            return{
                ...state,
                formulari: action.payload                
            }
        case types.uiFinalitzarFormulario:
            return{
                ...state,
                formulari: action.payload                
            }
        case types.uiActualizarResultats:
            return{
                ...state,
                resultatsID: action.payload                
            }
        case types.uiActualizarResultatCat:
            return{
                ...state,
                resultatCat: action.payload                
            }
        case types.uiActualizarTextosState:
            return{
                ...state,
                textosState: action.payload                
            }
        default:
            return state;

    }
}