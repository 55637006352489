import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFormularioCat1, addProgramaCat1 } from '../../actions/configFormularioAction';
import { crearErrorAction } from '../../actions/ui';
import { useForm } from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Pregunta } from '../../helpers/Pregunta';

export const FormularioCat1 = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation("generic");
  const { auth, configFormulario, ui } = useSelector((state) => state);
  const { msgError } = ui;

  const [formValues, handleInputChange, reset] = useForm({});

  // Generar preguntas dinámicamente desde infoFormularioTextCategoria
  const questions = configFormulario.infoFormularioTextCategoria
    ? Object.entries(configFormulario.infoFormularioTextCategoria.Categoria1)
        .filter(([key]) => key.startsWith('Cat1_Preg')) // Filtrar solo las preguntas
        .map(([key, value]) => ({
          id: key,
          label: value,
          type: 'text',
        }))
    : [];

  // Construir preguntas del programa dinámicamente
  const programQuestions = configFormulario.infoFormularioTextCategoria
    ? Object.entries(configFormulario.infoFormularioTextCategoria.Categoria1)
        .filter(([key]) => key.startsWith('Cat1_Prog')) // Filtrar solo las preguntas de programa
        .map(([key, value]) => ({
          id: key,
          label: value,
          type: 'select',
          options: [
            { value: '', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Seleccionar },
            { value: '2', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Si},
            { value: '1', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_Mitat },
            { value: '0', label: configFormulario.infoFormularioTextCategoria.CategoriaDesplegableProg.CatProg_Desplegable_No },
          ],
        }))
    : [];

  const handleCalcularCat = () => {
    if (validarFormulas()) {
      const payload = questions.map((q) => formValues[q.id]);
      dispatch(addFormularioCat1(auth.tokenJwt, ...payload, configFormulario.infoFormulario.IdEvaluacion));
      reset();
    }
  };

  const validarFormulas = () => {
    let esCorrecte = true;
    let msgError = "";

    questions.forEach((question, index) => {
      const value = formValues[question.id];
      if (isNaN(value) || !value) {
        esCorrecte = false;
        msgError += `${t("formulario.msgError1")}${index + 1} ${t("formulario.msgError2")}\n`;
      }
    });

    if (!esCorrecte) {
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  };

  const handleCalcularPrograma = () => {
    if (validarProgramas()) {
      const payload = programQuestions.map((q) => formValues[q.id]);
      dispatch(addProgramaCat1(auth.tokenJwt, ...payload, configFormulario.infoFormulario.IdEvaluacion));
    }
  };

  const validarProgramas = () => {
    let esCorrecte = true;
    let msgError = "";

    programQuestions.forEach((question, index) => {
      const value = formValues[question.id];
      if (isNaN(value) || !value) {
        esCorrecte = false;
        msgError += `${t("formulario.msgError1")}${index + 1} ${t("formulario.msgError2")}\n`;
      }
    });

    if (!esCorrecte) {
      dispatch(crearErrorAction(msgError));
    }
    return esCorrecte;
  };

  return (
    <div className="ac-panel">
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {msgError && <div className="alert alert-danger">{msgError}</div>}

        {configFormulario.infoFormularioCategoria.ResultatCat1 == null ? (
          <>
            {questions.map((question) => (
              <Pregunta
                key={question.id}
                label={question.label}
                name={question.id}
                value={formValues[question.id] || ''}
                onChange={handleInputChange}
                type={question.type}
              />
            ))}
            <div>
              <button onClick={handleCalcularCat} className="btn-turquesa">
                {t("formulario.enviar")}
              </button>
            </div>
          </>
        ) : (
          <>
            {programQuestions.map((question) => (
              <Pregunta
                key={question.id}
                label={question.label}
                name={question.id}
                value={formValues[question.id] || ''}
                onChange={handleInputChange}
                type={question.type}
                options={question.options}
              />
            ))}
            <div>
              <button onClick={handleCalcularPrograma} className="btn-turquesa">
                {t("formulario.enviar")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
