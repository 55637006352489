import { types } from "../types/types"
import {esborrarErrorAction, crearErrorAction, crearTotOkAction, uiActualizarResultatCatAction, uiIniciarFormularioAction, uiActualizarTextosStateAction } from "./ui";



// export const recuperarConstantesIndicadoresFormulario = (token,IdIndicadores,IdConstantes) =>{
    
//     //dispatch(uiIniciarCarregaAction());     

//     return (dispatch) => {  
//         var dadesFormulari = {
//             'IdConstantes':IdConstantes,
//             'IdIndicadores':IdIndicadores
//         };
//         //Preparem els parametres post per enviar
//         var formBody = [];
//             for (var property in dadesFormulari) {
//             var encodedKey = encodeURIComponent(property);
//             var encodedValue = encodeURIComponent(dadesFormulari[property]);
//             formBody.push(encodedKey + "=" + encodedValue);
//             }
//             formBody = formBody.join("&");
        
//             const requestOptions = {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
//                 credentials: 'include',
//                 body: formBody,
//                 mode: 'cors'
//             };

//             fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/info/constantesindicadores',requestOptions)
//             .then(async response => {               
//                 const isJson = response.headers.get('content-type')?.includes('application/json');
//                 const data = isJson && await response.json();
 
//                 if (!response.ok) {
//                 dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
//                 }else
//                 {
//                     if(data.ok)
//                     {
//                         dispatch(ConfigFormularioRecuperarConstantesFormulario(data.contingut.constantes));
//                         dispatch(ConfigFormularioRecuperarIndicadoresFormulario(data.contingut.indicadores));

//                     }else
//                     {
                        
 
//                     }  
//                 }

              
//             })
//             .catch(error => {
//              console.error('There was an error!', error);
           
//             });
    
        
//         }
    



// }
export const addFormularioCat1 = (token,Cat1_1,Cat1_2,Cat1_3,Cat1_4,Cat1_5,Cat1_6,Cat1_7,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat1_1':Cat1_1,
            'Cat1_2':Cat1_2,
            'Cat1_3':Cat1_3,
            'Cat1_4':Cat1_4,
            'Cat1_5':Cat1_5,
            'Cat1_6':Cat1_6,
            'Cat1_7':Cat1_7,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat1',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 1 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        
                        dispatch(crearErrorAction(data.msg));
                       
 
                    }  
                }

              
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
    
        
        }
    



}
export const addFormularioCat2 = (token,cat2_1, cat2_2, cat2_3, cat2_4, cat2_5, cat2_6,cat2_7,cat2_8,cat2_9,cat2_10,cat2_11,cat2_12,cat2_13,cat2_14,cat2_15,cat2_16,cat2_17,cat2_18,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat2_1':cat2_1,
            'Cat2_2':cat2_2,
            'Cat2_3':cat2_3,
            'Cat2_4':cat2_4,
            'Cat2_5':cat2_5,
            'Cat2_6':cat2_6,
            'Cat2_7':cat2_7,
            'Cat2_8':cat2_8,
            'Cat2_9':cat2_9,
            'Cat2_10':cat2_10,
            'Cat2_11':cat2_11,
            'Cat2_12':cat2_12,
            'Cat2_13':cat2_13,
            'Cat2_14':cat2_14,
            'Cat2_15':cat2_15,
            'Cat2_16':cat2_16,
            'Cat2_17':cat2_17,
            'Cat2_18':cat2_18,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat2',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                    dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                    }else
                    {
                        if(data.ok)
                        {
                            dispatch(crearTotOkAction("Categoria 2 calculada correctament"));
                            dispatch(infoCategoria(token,IdEvaluacion));
                        }else
                        {
                            
                            dispatch(crearErrorAction(data.msg));
                           
     
                        }  
                    }
    
                  
                })
                .catch(error => {
                 console.error('There was an error!', error);
               
                });
        
        }
}
export const addFormularioCat3 = (token,cat3_1, cat3_2, cat3_3, cat3_4, cat3_5, cat3_6,cat3_7,cat3_8,cat3_9,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat3_1':cat3_1,
            'Cat3_2':cat3_2,
            'Cat3_3':cat3_3,
            'Cat3_4':cat3_4,
            'Cat3_5':cat3_5,
            'Cat3_6':cat3_6,
            'Cat3_7':cat3_7,
            'Cat3_8':cat3_8,
            'Cat3_9':cat3_9,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat3',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                console.log(response)
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 3 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion));
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        
                        dispatch(crearErrorAction(data.msg));
                       
 
                    }  
                }

              
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
    
        
        }
    



}
export const addFormularioCat4 = (token,cat4_1, cat4_2, cat4_3, cat4_4, cat4_5, cat4_6,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat4_1':cat4_1,
            'Cat4_2':cat4_2,
            'Cat4_3':cat4_3,
            'Cat4_4':cat4_4,
            'Cat4_5':cat4_5,
            'Cat4_6':cat4_6,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat4',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 4 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
 
                    }  
                }
             
            })
            .catch(error => {
             console.error('There was an error!', error);
            });
        }

}
export const addFormularioCat5 = (token,cat5_1, cat5_2, cat5_3, cat5_4, cat5_5, cat5_6,cat5_7,cat5_8,cat5_9,cat5_10,cat5_11,cat5_12,cat5_13,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat5_1':cat5_1,
            'Cat5_2':cat5_2,
            'Cat5_3':cat5_3,
            'Cat5_4':cat5_4,
            'Cat5_5':cat5_5,
            'Cat5_6':cat5_6,
            'Cat5_7':cat5_7,
            'Cat5_8':cat5_8,
            'Cat5_9':cat5_9,
            'Cat5_10':cat5_10,
            'Cat5_11':cat5_11,
            'Cat5_12':cat5_12,
            'Cat5_13':cat5_13,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat5',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 5 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                       dispatch(crearErrorAction(data.msg));
                    }  
                }
              
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addFormularioCat6 = (token,cat6_1, cat6_2, cat6_3, cat6_4, cat6_5, cat6_6,cat6_7,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat6_1':cat6_1,
            'Cat6_2':cat6_2,
            'Cat6_3':cat6_3,
            'Cat6_4':cat6_4,
            'Cat6_5':cat6_5,
            'Cat6_6':cat6_6,
            'Cat6_7':cat6_7,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat6',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 6 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));

                    }  
                }
            
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }
}

export const addFormularioCat7 = (token,cat7_1, cat7_2, cat7_3, cat7_4, cat7_5,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat7_1':cat7_1,
            'Cat7_2':cat7_2,
            'Cat7_3':cat7_3,
            'Cat7_4':cat7_4,
            'Cat7_5':cat7_5,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat7',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 7 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                     }  
                }
              
            })
            .catch(error => {
             console.error('There was an error!', error);
            });
        }
}
export const addFormularioCat8 = (token,cat8_1, cat8_2, cat8_3, cat8_4, cat8_5, cat8_6,cat8_7,cat8_8,cat8_9,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat8_1':cat8_1,
            'Cat8_2':cat8_2,
            'Cat8_3':cat8_3,
            'Cat8_4':cat8_4,
            'Cat8_5':cat8_5,
            'Cat8_6':cat8_6,
            'Cat8_7':cat8_7,
            'Cat8_8':cat8_8,
            'Cat8_9':cat8_9,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat8',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 8 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
             
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }
}
export const addFormularioCat9 = (token,cat9_1, cat9_2, cat9_3, cat9_4,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat9_1':cat9_1,
            'Cat9_2':cat9_2,
            'Cat9_3':cat9_3,
            'Cat9_4':cat9_4,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/cat9',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria 9 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat1 = (token,cat1_1, cat1_2, cat1_3, cat1_4, cat1_5,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat1_1': cat1_1,
            'Cat1_2': cat1_2,
            'Cat1_3': cat1_3,
            'Cat1_4': cat1_4,
            'Cat1_5': cat1_5,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/1',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 1  calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat2 = (token, cat2_1, cat2_2, cat2_3, cat2_4, cat2_5, cat2_6,cat2_7,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {          
            'Cat2_1': cat2_1,
            'Cat2_2': cat2_2,
            'Cat2_3': cat2_3,
            'Cat2_4': cat2_4,
            'Cat2_5': cat2_5,
            'Cat2_6': cat2_6,
            'Cat2_7': cat2_7,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/2',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programas 2 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat3 = (token, cat3_1, cat3_2, cat3_3, cat3_4,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat3_1': cat3_1,
            'Cat3_2': cat3_2,
            'Cat3_3': cat3_3,
            'Cat3_4': cat3_4,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/3',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 3 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat4 = (token,cat4_1, cat4_2,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat4_1': cat4_1,
            'Cat4_2': cat4_2,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/4',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 4 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat5 = (token,cat5_1, cat5_2, cat5_3, cat5_4,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat5_1': cat5_1,
            'Cat5_2': cat5_2,
            'Cat5_3': cat5_3,
            'Cat5_4': cat5_4,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/5',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 5 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat6 = (token,cat6_1,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat6_1': cat6_1,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/6',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 6 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat7 = (token,cat7_1, cat7_2,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat7_1': cat7_1,
            'Cat7_2': cat7_2,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/7',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 7 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat8 = (token,cat8_1, cat8_2,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat8_1': cat8_1,
            'Cat8_2': cat8_2,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/8',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 8 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const addProgramaCat9 = (token,cat9_1, cat9_2,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'Cat9_1': cat9_1,
            'Cat9_2': cat9_2,
            'IdEvaluacion':IdEvaluacion,

        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/Programas/9',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Categoria dels Programa 9 calculada correctament"));
                        dispatch(infoCategoria(token,IdEvaluacion))
                        dispatch(esborrarErrorAction());
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}
export const iniciarFormulario = (token) =>{
    //Aquesta funció (Middleware), retorna un callback. Just quan finalitza la petició, execitarà l'acció 
    return (dispatch) =>{
        //dispatch(uiIniciarCarregaAction());      
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };
            //TODO - Crear un fitxer on guardem constants segons l'entorn 
        fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/add/formulario',requestOptions)
            .then(async response => {
               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                
        
                // check for error response
                if (!response.ok) {
                    
                    // get error message from body or default to response status
                    //const error = (data && data.message) || response.status;
                    //dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        
                       let { IdEvaluacion,IdMunicipio,IdResponsable,DataInicio_Evaluacion,DataFin_Evaluacion,ResultatGlobal} = data.contingut;
                       dispatch(ConfigFormulario(data.contingut)); 
                       dispatch(uiIniciarFormularioAction())

                      
                    //    dispatch(ConfigFormularioRecuperarConstantesFormulario(data.contingut.ConstantesFormulario));
                    //    dispatch(ConfigFormularioRecuperarIndicadoresFormulario(data.contingut.Indicadores));          
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }
                }

                //dispatch(uiFinalitzarCarregaAction());
                //element.innerHTML = data.id;
            })
            .catch(error => {
               // element.parentElement.innerHTML = `Error: ${error}`;
               // dispatch(crearErrorAction("Excepció no controlada -  " + JSON.stringify(error)));
                console.error('There was an error!', error);
               // dispatch(uiFinalitzarCarregaAction());
            });
      
        /*setTimeout(() => {
                //Als 3 segons, farem un dispatch del l'acció Login. 
                
                dispatch(login(123,345,"German, usuari autenticat"));

            }, 3500);*/
    }


}
export const infoCategoria = (token,IdEvaluacion) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'IdEvaluacion':IdEvaluacion,

        };
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/info/cat',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(ConfigFormularioCategoria(data.contingut));
                        
                        
                    }else
                    {
                        
                        
                       
 
                    }  
                }

              
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
       
        }

}
export const infoTextCategoria = (token) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        
        //Preparem els parametres post per enviar
      
        
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/info/textCat',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(ConfigTextFormularioCategoria(data.contingut));
                        dispatch(uiActualizarTextosStateAction("1"))
                        
                    }else
                    {
                        
                        
                       
 
                    }  
                }

              
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
       
        }

}
export const updateTextosModificats = (token,textosModificats,categoria) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        const bodyData = JSON.stringify({
            textosModificats: textosModificats,
        });
        //Preparem els parametres post per enviar

        
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Enviar como JSON
                    'Accept': 'application/json',
                    'Authorization': token,
                },
                credentials: 'include',
                body: bodyData, 
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/info/modText',requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(ActualizarTextoCategoria(categoria,data.contingut));
                        
                    }else
                    {
                        
                        
                       
 
                    }  
                }

              
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
       
        }

}
export const addResultatsCategoria = (token,IdEvaluacion,categoria) =>{
    
    //dispatch(uiIniciarCarregaAction());     

    return (dispatch) => {  
        var dadesFormulari = {
            'IdEvaluacion':IdEvaluacion,
        };
        // console.log(dadesFormulari);
        //Preparem els parametres post per enviar
        var formBody = [];
            for (var property in dadesFormulari) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(dadesFormulari[property]);
            formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Accept':'application/json','Authorization' : token },
                credentials: 'include',
                body: formBody,
                mode: 'cors'
            };

            fetch( process.env.REACT_APP_DOMINI_API_CIUDAD + '/resultat/'+categoria,requestOptions)
            .then(async response => {               
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
 
                if (!response.ok) {
                dispatch(crearErrorAction("Error del servei d'autenticació: " +  JSON.stringify(data)));
                }else
                {
                    if(data.ok)
                    {
                        dispatch(crearTotOkAction("Resultats de la categoria"+categoria+" ."));
                        dispatch(resultatsCategoria(data.contingut));
                        dispatch(uiActualizarResultatCatAction(true));
                    }else
                    {
                        dispatch(crearErrorAction(data.msg));
                    }  
                }
            })
            .catch(error => {
             console.error('There was an error!', error);
           
            });
        }

}

// export const ConfigFormularioRecuperarConstantesFormulario = (contingut) =>{
//     return {
//         type: types.configFormularioRecuperarConstantesFormulario,
//         contingut
//     }

// }
// export const ConfigFormularioRecuperarIndicadoresFormulario = (contingut) =>{
//     return {
//         type: types.configFormularioRecuperarIndicadoresFormulario,
//         contingut
//     }

// }
export const resultatsCategoria = (contingut) =>{
    return {
        type: types.configFormularioResultats,
        contingut
    }

}


export const ConfigFormulario = (contingut) =>{
    return {
        type: types.configFormularioInfo,
        contingut
    }

}
export const ConfigFormularioCategoria = (contingut) =>{
    return {
        type: types.configFormularioCategoria,
        contingut
    }

}
export const ConfigTextFormularioCategoria = (contingut) =>{
    return {
        type: types.configTextFormularioCategoria,
        contingut,
    }

}
export const ActualizarTextoCategoria = (categoria, nuevosTextos) => {
    return {
        type: types.configActualizarTextoCategoria,
        categoria, // El nombre de la categoría que estás actualizando (ej. "categoria2")
        nuevosTextos, // El objeto con los textos modificados, ej. { "Cat2_Preg6": "Nuevo texto" }
    };
};
